import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyDFmP6lxoPSp4j6O1ScFnA5nntZUm0qjIQ",
	authDomain: "moon-upload.firebaseapp.com",
	projectId: "moon-upload",
	storageBucket: "moon-upload.appspot.com",
	messagingSenderId: "556901715440",
	appId: "1:556901715440:web:4393406e62d82adbcfa135"
};


const app = initializeApp(firebaseConfig);